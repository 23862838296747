import { mediacoreApi } from "../mediacoreApi"

const configurationExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getConfigurations: builder.query({
      query: CLIENT_ID =>
        `core/custom-login/configuraciones/?client=${CLIENT_ID}`
    }),

  }),
  overrideExisting: false,
})

export const { useGetConfigurationsQuery } = configurationExtended
