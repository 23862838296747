import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Contacto/main'
import BannerTop from "../components/Global/Modules/MediaPush/BannerTop";

const IndexPage = () => (
  <Layout>
    <Seo title="Contacto"/>
    <BannerTop page="CON"/>
    <Main />
  </Layout>
)

export default IndexPage
