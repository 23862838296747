import React from "react"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { useGetConfigurationsQuery } from "../redux/mediacore/configuration"

const LateralContact = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        design {
          icons
        }
        name
        global_email
        global_phone
        global_broker_id
        social {
          facebook
          instagram
          twitter
          youtube
          linkedin
          tiktok
        }
        keys {
          captcha
        }
        branch_office {
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        env {
          CLIENTID
        }
        sections {
          services {
            title
            subtitle
            paragraph
            form
          }
          footer {
            title_contact
            title_social
            title_newsletter
            title_office
            button_contact {
              link
              value
            }
          }
        }
      }
    }
  `)

  const {
    design: { icons },
    env,
  } = realEstate
  const iconsStyle =
    icons === "Solid" || icons === "Light" ? `-${icons.toLowerCase()}` : ""
  const { data: modules } = useGetConfigurationsQuery(env.CLIENTID)
  const INVESTOR_CONFIGURATION = modules?.find(
    config => config.module === "INVESTOR"
  )
  const OWNER_CONFIGURATION = modules?.find(config => config.module === "OWNER")

  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "").toLowerCase()
  const isPageContact = pathname === "contacto"
  const hasModules =
    (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module)?.length > 0

  return (
    <div className="center-contact">
      <div className="row">
        <div
          className={
            "col-12" +
            (hasModules && isPageContact ? " max-heigth-scroll up-to-52" : "")
          }
        >
          <div className="item-contact">
            <h5>{realEstate.sections.footer.title_contact}</h5>
            <a
              className="hover-service-link"
              target="_blank"
              href={"mailto:" + realEstate.global_email}
            >
              {realEstate.global_email}
            </a>
            {realEstate?.global_broker_id.map((broker, index) => (
              <p className={"mb-0 mb-lg-0 " + (broker === " " && "d-none")}>
                {broker}
              </p>
            ))}
          </div>
          <div className="mb-lg-0">
            {realEstate.branch_office.map((item, index) => (
              <div className="item-contact">
                <h5>
                  {realEstate.sections.footer.title_office + " "}
                  {item.name}
                </h5>
                <p>{item.address}</p>
                <p>{item.region}</p>
                <a
                  target="_blank"
                  className={"hover-link " + (!item.contact.mail && "d-none")}
                  href={"mailto:" + item.contact.mail}
                >
                  {item.contact.mail}
                </a>
                {item.contact.phone.map(phone => (
                  <p>Teléfono {phone}</p>
                ))}
                {item.contact.whatsapp.map(whatsapp => (
                  <a
                    target="_blank"
                    href={
                      "https://api.whatsapp.com/send?" +
                      "phone=" +
                      whatsapp
                        .replaceAll(" ", "")
                        .replaceAll("-", "")
                        .replaceAll("+", "")
                    }
                  >
                    Whatsapp {whatsapp}
                  </a>
                ))}
                <p className={item.broker_id === "" && "d-none"}>
                  Matrícula {item.broker_id}
                </p>
              </div>
            ))}
          </div>
          <div className="item-contact">
            <h5 className="mb-3">
              {realEstate?.sections?.footer?.title_social}
            </h5>
            <a
              target="_blank"
              href={realEstate.social.instagram}
              class={realEstate.social.instagram ? "d-inline" : "d-none"}
            >
              <i class={`icon-instagram${iconsStyle}`}></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.facebook}
              class={realEstate.social.facebook ? "d-inline" : "d-none"}
            >
              <i class={`icon-facebook${iconsStyle}`}></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.linkedin}
              class={realEstate.social.linkedin ? "d-inline" : "d-none"}
            >
              <i class={`icon-linkedin${iconsStyle}`}></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.youtube}
              class={realEstate.social.youtube ? "d-inline" : "d-none"}
            >
              <i class={`icon-youtube${iconsStyle}`}></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.twitter}
              class={realEstate.social.twitter ? "d-inline" : "d-none"}
            >
              <i class={`icon-twitter${iconsStyle}`}></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.tiktok}
              class={realEstate.social.tiktok ? "d-inline" : "d-none"}
            >
              <i class={`icon-tiktok${iconsStyle}`}></i>
            </a>
          </div>
        </div>
        {hasModules && isPageContact ? (
          <div className="col-12 mt-lg-4">
            {/* Investor & Owner - Desktop */}
            <div
              className={
                "item-contact row mb-0 mt-lg-4 button-separator " +
                (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                  ? "d-block"
                  : " d-none")
              }
            >
              <h5 className="">ACCESOS PRIVADOS</h5>
              {INVESTOR_CONFIGURATION?.module && (
                <div className="d-block">
                  <a
                    className="btn btn-investor mb-2 d-block d-lg-inline-block"
                    target={"_blank"}
                    href={INVESTOR_CONFIGURATION.url_login}
                  >
                    {INVESTOR_CONFIGURATION.text_cta || "INVERSORES"}
                  </a>
                </div>
              )}
              {OWNER_CONFIGURATION?.module && (
                <div className="d-block">
                  <a
                    className="btn btn-investor mb-2 d-block d-lg-inline-block"
                    target={"_blank"}
                    href={OWNER_CONFIGURATION.url_login}
                  >
                    {OWNER_CONFIGURATION.text_cta || "PROPIETARIOS"}
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LateralContact
